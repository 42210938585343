<script setup>
import image1 from "../assets/images/books/dementia-care.jpg"
import image2 from "../assets/images/books/run-yourself-fit.jpg"
import image3 from "../assets/images/books/womens-running-65.png"

const books = [
  {
    imageSrc: image1,
    link: "https://www.amazon.co.uk/Dementia-Care-Guide-Sheldon-Guides/dp/184709399X/ref=sr_1_3",
    alt: "Dementia Care - A Guide Book Cover"
  },
  {
    imageSrc: image2,
    link: "https://www.amazon.co.uk/Run-Yourself-Fit-Simple-Healthier/dp/1849537992/ref=sr_1_1",
    alt: "Run Yourself Fit Book Cover"
  },
  {
    imageSrc: image3,
    link: "https://www.thesouthafrican.com/womens-running-65/",
    alt: "Women's Running Magazine"
  }
]
</script>

<template>
  <div class="books">
    <a v-for="book in books" :key="book.link" :href="book.link" target="_blank" class="book" rel="noreferrer">
      <img :src="book.imageSrc" :alt="book.alt" />
    </a>
  </div>
</template>

<style scoped>
.books {
  display: flex;
  justify-content: center;
  align-items: center;
}

.book {
  flex-basis: 33%;
  transition: 250ms ease;
  box-shadow: 0 10px 30px -20px #0002;
}

.book+.book {
  margin: 0 0 0 0.5rem;
}

.book img {
  display: block;
  max-width: 100%;
}

a.book {
  transition: transform 250ms ease;
}

a.book:hover {
  transform: scale(1.1);
}

@media (min-width: 1080px) {
  .books {
    flex-direction: row;
  }

  .book+.book {
    margin: 0 0 0 1rem;
  }
}
</style>
