<template>
  <div class="contact">
    <h1>Get In Touch</h1>
    <a href="mailto:christina@healthycontent.co.uk?subject=Enquiry" target="_blank" class="button">
      Send An EMail
    </a>
    <h3>Call on <a href="tel:07957360242">07957 360 242</a> or find us at:</h3>
    <div class="links">
      <a href="mailto:christina@healthycontent.co.uk?subject=Enquiry" target="_blank">
        <i class="fas fa-envelope-square" />
        <strong>christina@healthycontent.co.uk</strong>
      </a>
      <a href="https://twitter.com/christinahccollison" target="_blank">
        <i class="fab fa-twitter-square" />
        <strong>Twitter</strong>
      </a>
      <a href="https://www.facebook.com/healthycontent1/" target="_blank">
        <i class="fab fa-facebook-square" />
        <strong>Facebook</strong>
      </a>
    </div>
  </div>
</template>

<style scoped>
.contact {
  flex-grow: 1;
}

.button {
  display: inline-block;
  cursor: pointer;
  border: none;
  font-size: var(--fs-responsive-sm);
  font-weight: 700;
  background-color: var(--color-accent-dark);
  color: white;
  text-transform: uppercase;
  letter-spacing: var(--lts-small);
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 5px 10px 2px #0001;

  &:hover {
    background-color: var(--color-accent);
  }
}

.links {
  display: flex;
  flex-direction: column;
  max-width: max-content;
  margin: 0 auto;

  a {
    font-size: smaller;
    margin: 0.5ch;
    display: flex;
    gap: .5em;
    color: currentColor;
    align-items: baseline;
    padding: 0.5rem 1rem .3rem;
    box-shadow: 0 5px 10px 2px #0001;
    border-radius: .5rem;
    background-color: #fffa;
    transition: background-color 350ms ease;

    &:hover {
      background-color: #fff;
      transition: background-color 150ms ease;
    }
  }

  @media (min-width: 1080px) {
    flex-direction: row;
    justify-content: center;

    a {
      margin: 1.5ch;
      margin-top: 0;
      font-size: initial;
    }
  }
}
</style>
