<script setup>
import TheBrand from '../branding/TheBrand'

const year = new Date().getFullYear()
</script>

<template>
  <footer>
    <div class="container">
      <div class="little">
        &copy; 2020 - {{ year }}
        <strong>Healthy Content</strong>
      </div>
      <ul class="social-links">
        <a href="tel:07957360242">
          <i class="fas fa-phone" />
        </a>
        <a href="mailto:christina@healthycontent.co.uk?subject=Enquiry" target="_blank">
          <i class="fas fa-envelope" />
        </a>
        <a href="https://twitter.com/christinahccollison" target="_blank">
          <i class="fab fa-twitter" />
        </a>
        <a href="https://www.facebook.com/healthycontent1/" target="_blank">
          <i class="fab fa-facebook" />
        </a>
      </ul>
      <TheBrand />
    </div>
  </footer>
</template>

<style scoped>
footer {
  width: 100%;
  padding: 1em 0;
  background-color: white;

  .container {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media (min-width: 768px) {
      grid-template-columns: auto 1fr auto;
    }
  }

  .little {
    font-size: 0.8125rem;
  }

  .social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
    margin: 0;
    padding: 0;

    a {
      color: currentColor;
    }
  }
}
</style>
